@tailwind base;
@tailwind components;
@tailwind utilities;

@import "react-alice-carousel/lib/alice-carousel.css";

@layer components {
  .btn-default {
    @apply inline-block text-sm px-4 py-3 leading-none border rounded text-blue-nav-active border-blue-nav-active bg-purple-nav-btn hover:border-grey-nav-inactive hover:text-grey-title hover:bg-white
  }
  .btn-white {
    @apply inline-block text-sm px-4 py-3 leading-none border rounded-lg text-blue-nav-active border-blue-nav-active bg-white hover:border-grey-nav-inactive hover:text-grey-nav-inactive hover:bg-white
  }
  .btn-gradient {
    @apply inline-block text-sm px-4 py-3 leading-none border rounded-lg text-white border-blue-nav-active bg-gradient-to-r from-blue-start to-blue-end hover:border-grey-nav-inactive hover:text-grey-nav-inactive hover:bg-white
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active {
  @apply text-blue-nav-active
}

.alice-carousel{
  .alice-carousel__stage-item{
    vertical-align: middle;
  }
}

